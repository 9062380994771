function changeTypeInputPassword() {
  const listBtn = document.querySelectorAll('.oko-visibility-password-btn');

  listBtn.forEach(el => {
    el.addEventListener('click', function (evt) {
      const parent = this.parentNode;
      const passwordField = parent.querySelector('input');
      const btnHiden = parent.querySelector('.js-oko-hidden-password-btn');
      const btnShow = parent.querySelector('.js-oko-visible-password-btn');

      if (passwordField.type === 'password') {
        passwordField.type = 'text';
        btnShow.classList.add('is-hidden');
        btnHiden.classList.remove('is-hidden');
      } else {
        passwordField.type = 'password';
        btnShow.classList.remove('is-hidden');
        btnHiden.classList.add('is-hidden');
      }
    });
  });
}

class FormValidator {
  constructor(form) {
    this.form = form;
    this.userPassword = form.elements.USER_PASSWORD;
    this.userConfirmPassword = form.elements.USER_CONFIRM_PASSWORD;
    this.submitBtn = form.elements.submit_btn;
    this.errors = [];
    this.regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).+$/;

    this.form.addEventListener('submit', this.handleSubmit.bind(this));
  }

  handleSubmit(e) {
    e.preventDefault();
    this.clearErrors();
    this.removeClassElement(this.userPassword, 'error-field');
    this.removeClassElement(this.userConfirmPassword, 'error-field');

    if (!this.regexp.test(this.userPassword.value) || this.userPassword.value.length < 10) {
      this.userPassword.classList.add('error-field');
      this.removeClassElement(this.submitBtn, 'wait');
      this.showErrorAfterElement(this.userPassword.parentNode, 'Пароль должен быть не менее 10 символов, содержать латинские символы верхнего регистра, содержать латинские символы нижнего регистра, содержать цифры, содержать знаки пунктуации.');
      return;
    }
    if (this.userPassword.value !== this.userConfirmPassword.value) {
      this.userConfirmPassword.classList.add('error-field');
      this.removeClassElement(this.submitBtn, 'wait');
      this.showErrorAfterElement(this.userConfirmPassword.parentNode, 'Пароль и подтверждение не совпадают');
      return;
    }

    this.form.submit();
  }

  showErrorAfterElement(element, message) {
    const errorElement = document.createElement('div');
    errorElement.classList.add('error-message');
    errorElement.textContent = message;
    element.insertAdjacentElement('afterend', errorElement);
    this.errors.push(errorElement);
  }

  clearErrors() {
    this.errors.forEach(el => el.remove());
    this.errors = [];
  }

  removeClassElement(element, className) {
    element.classList.remove(className);
  }
}

BX.ready(function () {
  changeTypeInputPassword();

  if (location.search.includes('change_password')) {
    const formAuth = document.forms.form_auth;
    if (formAuth) {
      new FormValidator(formAuth);
    }
  }
});
